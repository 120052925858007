import React from "react"
import { graphql } from "gatsby"
import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./styles.scss"

class About extends React.Component {
    render() {
      const { data } = this.props
      const siteTitle = data.site.siteMetadata.title
      return (
        <Layout location={this.props.location} title={siteTitle}>
          <SEO title="About" />
          <Bio />
          <div className="page">
            <h1>About</h1>
            <p>Hello, this is the about page.</p>
          </div>
        </Layout>
      )
    }
  }
  
  export default About
  
  export const pageQuery = graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `